/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row, Col, Tabs, Tab, Modal } from 'react-bootstrap';
import './ViewAllServices.css';
import SearchServices from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPrint, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import Invoice from './Invoice';
import ExtraInvoice from './ExtraInvoice';
import { TbFileInvoice } from "react-icons/tb";

const ViewAllInvoices = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage] = useState(5);
  const [selectedService, setSelectedService] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [key, setKey] = useState('CASH');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      // Retrieve parsedUserData from localStorage
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('User data not found in localStorage');
      }
  
      const parsedUserData = JSON.parse(storedUserData);
  
      // Ensure staff_id is available
      if (!parsedUserData.staff_id) {
        throw new Error('Staff ID not found in user data');
      }
  
      // Fetch services using staff_id
      const response = await fetch(`${BASE_URL}/service/staff/${parsedUserData.staff_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch services');
      }
  
      const servicesData = await response.json();
  
      // Fetch additional customer and invoice details for each service
      const servicesWithCustomers = await Promise.all(
        servicesData.map(async (service) => {
          try {
            if (service.customer_id) {
              // Fetch customer details
              const customerResponse = await fetch(`${BASE_URL}/customers/${service.customer_id}`);
              if (!customerResponse.ok) {
                throw new Error(`Failed to fetch customer for customer ID ${service.customer_id}`);
              }
              const customerData = await customerResponse.json();
  
              if (customerData.data && customerData.data.firstName && customerData.data.lastName) {
                // Fetch invoice details
                const invoiceResponse = await fetch(`${BASE_URL}/service/${service.service_id}`);
                const invoiceData = await invoiceResponse.json();
  
                // Fetch extra invoice details
                const extraInvoiceResponse = await fetch(`${BASE_URL}/service/updatesave/${service.service_id}`);
                const extraInvoiceData = await extraInvoiceResponse.json();
  
                return {
                  ...service,
                  customerName: `${customerData.data.title || ''} ${customerData.data.firstName || ''} ${customerData.data.lastName || ''}`.trim(),
                  customerEmail: customerData.data.email || '',
                  customerContactNumber: customerData.data.contactNo || '',
                  invoice: invoiceData || {},
                  extraInvoice: extraInvoiceData || {},
                };
              } else {
                throw new Error(`Invalid customer data for customer ID ${service.customer_id}`);
              }
            } else {
              throw new Error(`Customer ID not found for service ID ${service._id}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error);
            return {
              ...service,
              customerName: 'Unknown',
              customerEmail: 'Unknown',
              customerContactNumber: 'Unknown',
              invoice: {},
              extraInvoice: {},
            };
          }
        })
      );
  
      // Sort services by creation date (descending)
      servicesWithCustomers.sort((a, b) => new Date(b.createdAtService) - new Date(a.createdAtService));
  
      setServices(servicesWithCustomers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filteredServices = services.filter(service => {
    const invoiceId = service._id || '';
    const customerName = service.customerName || '';
    const vehicleNumber = service.vehicle_no || '';
    const paymentType = service.paymentType || '';
    const serviceStatus = service.service_status || '';
    
    const searchQuery = searchTerm.toLowerCase();
  
    return (
      invoiceId.toLowerCase().includes(searchQuery) ||
      customerName.toLowerCase().includes(searchQuery) ||
      vehicleNumber.toLowerCase().includes(searchQuery) ||
      paymentType.toLowerCase().includes(searchQuery) ||
      serviceStatus.toLowerCase().includes(searchQuery)
    );
  });

  const servicesByPaymentMethod = filteredServices.filter(service => {
    if (key === 'CASH') {
      return service.paymentType === 'CASH';
    } else if (key === 'CREDIT') {
      return service.paymentType === 'CREDIT';
    }
    return true;
  });

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);
  const totalPages = Math.ceil(filteredServices.length / servicesPerPage);

  const generateInvoice = (id, type) => {
    const service = services.find(service => service.service_id === id);
    if (service) {
      setSelectedService(service);
      setInvoiceType(type);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedService(null);
    setShowInvoiceModal(false);
    setInvoiceType('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowDeleteModal = (id) => {
    setServiceToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setServiceToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    if (!serviceToDelete) return;

    try {
      const response = await fetch(`${BASE_URL}/service/delete/${serviceToDelete}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedServices = services.filter(service => service.service_id !== serviceToDelete);
        setServices(updatedServices);
        toast.success('Service deleted successfully');
      } else {
        throw new Error('Failed to delete service');
      }
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service. Please try again.');
    } finally {
      handleCloseDeleteModal();
    }
  };

  return (
    <div className="view-all-services">
      <h2 className="text-center">All Services</h2>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="CASH" title="CASH">
          <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Service ID</th>
                <th>Vehicle Number</th>
                <th>Created Date</th>
                <th>Total Amount</th>
                <th>Customer Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentServices.map(service => (
                <tr key={service.service_id}>
                  <td>{service.service_id}</td>
                  <td>{service.vehicle_no}</td>
                  <td>{new Date(service.createdAtService).toLocaleString()}</td>
                  <td>Rs. {service.totalExtraAmount}.00</td>
                  <td>{service.customerName}</td>
                  <td>
                    <Link to={`/update/${service.service_id}`} className="action-button">
                      <Button variant="warning" className="mr-2"><FaEdit /></Button>
                    </Link>
                    <Link to={`/view/${service.service_id}`} className="action-button">
                      <Button variant="primary" className="mr-2"><FaEye /></Button>
                    </Link>
                    {service.service_status === 'Completed' && (
                      <>
                        {/* <Button
                          variant="success"
                          onClick={() => generateInvoice(service.service_id, 'invoice')}
                          className="action-button"
                        >
                          <FaPrint className="mr-2" />
                        </Button> */}
                        <Button
                          variant="secondary"
                          onClick={() => generateInvoice(service.service_id, 'extraInvoice')}
                          className="action-button"
                        >
                          <TbFileInvoice className="mr-2" />
                        </Button>
                      </>
                    )}
                    <Button
                      variant="danger"
                      onClick={() => handleShowDeleteModal(service.service_id)}
                      className="action-button"
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="CREDIT" title="CREDIT">
          <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Service ID</th>
                <th>Vehicle Number</th>
                <th>Created Date</th>
                <th>Total Amount</th>
                <th>Customer Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentServices.map(service => (
                <tr key={service.service_id}>
                  <td>{service.service_id}</td>
                  <td>{service.vehicle_no}</td>
                  <td>{new Date(service.createdAtService).toLocaleString()}</td>
                  <td>Rs. {service.totalExtraAmount}.00</td>
                  <td>{service.customerName}</td>
                  <td>
                    <Link to={`/update/${service.service_id}`} className="action-button">
                      <Button variant="warning" className="mr-2"><FaEdit /></Button>
                    </Link>
                    <Link to={`/view/${service.service_id}`} className="action-button">
                      <Button variant="primary" className="mr-2"><FaEye /></Button>
                    </Link>
                    {service.service_status === 'Completed' && (
                      <>
                        {/* <Button
                          variant="success"
                          onClick={() => generateInvoice(service.service_id, 'invoice')}
                          className="action-button"
                        >
                          <FaPrint className="mr-2" />
                        </Button> */}
                        <Button
                          variant="secondary"
                          onClick={() => generateInvoice(service.service_id, 'extraInvoice')}
                          className="action-button"
                        >
                          <TbFileInvoice className="mr-2" />
                        </Button>
                      </>
                    )}
                    <Button
                      variant="danger"
                      onClick={() => handleShowDeleteModal(service.service_id)}
                      className="action-button"
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      {selectedService && (
        <Modal show={showInvoiceModal} onHide={handleCloseInvoice} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
              <ExtraInvoice service={selectedService} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseInvoice}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this service?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Invoice
        show={showInvoiceModal && invoiceType === 'invoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      /> */}
      <ExtraInvoice
        show={showInvoiceModal && invoiceType === 'extraInvoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
    </div>
  );
};

export default ViewAllInvoices;