import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Alert, Form } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './ViewAllServicesDetailsEachMonth.css'; // Import custom CSS
import BASE_URL from '../config';

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const ViewAllServicesDetailsEachMonth = () => {
  const [serviceData, setServiceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/service`);
        setServiceData(response.data);
      } catch (error) {
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchServiceData();
  }, []);

  useEffect(() => {
    const aggregateData = serviceData.reduce((acc, data) => {
      const month = new Date(data.createdAtService).getMonth();
      const year = new Date(data.createdAtService).getFullYear();
      const key = `${year}-${month + 1}`; // Key in YYYY-MM format

      if (!acc[key]) {
        acc[key] = {
          monthYear: `${monthNames[month]}/${year}`,
          serviceCount: 0,
          totalExtraAmount: 0,
          totalLaborAmount: 0,
          totalCashAmount: 0,
          totalCreditAmount: 0,
          totalSparePartsAmount: 0
        };
      }

      acc[key].serviceCount += 1;
      acc[key].totalExtraAmount += data.totalExtraAmount || 0;
      acc[key].totalLaborAmount += data.extraTasks.reduce((sum, task) => task.extraTaskType === 'Labor' ? sum + (task.extraAmount || 0) : sum, 0);
      acc[key].totalSparePartsAmount += data.tasks.reduce((sum, task) => task.taskType === 'VehiclePart' ? sum + ((task.amount || 0) * (task.unit || 0)) : sum, 0);
      acc[key].totalCashAmount += data.paymentType === 'CASH' ? (data.totalExtraAmount || 0) : 0;
      acc[key].totalCreditAmount += data.paymentType === 'CREDIT' ? (data.totalExtraAmount || 0) : 0;

      return acc;
    }, {});

    const aggregatedData = Object.values(aggregateData);

    // Filter data by searchTerm
    const filtered = aggregatedData.filter(data => {
      const [month, year] = data.monthYear.split('/');
      return month.toLowerCase().includes(searchTerm.toLowerCase()) || year.includes(searchTerm);
    });
    
    setFilteredData(filtered);
  }, [serviceData, searchTerm]);

  // Prepare data for the line chart
  const chartData = filteredData.map(data => ({
    monthYear: data.monthYear,
    totalIncome: data.totalLaborAmount + data.totalSparePartsAmount,
    totalCashAmount: data.totalCashAmount,
    totalCreditAmount: data.totalCreditAmount,
  }));

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" />
        <p>Loading...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <h2 className="mb-4">Monthly Service Details</h2>

      {/* Search bar */}
      <Form.Control
        type="text"
        placeholder="Search by month or year"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      {/* Line chart */}
      <div className="chart-container mb-4" style={{ height: 400, marginLeft: "47px" }}>
        <ResponsiveContainer>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="monthYear" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="totalCashAmount" stroke="#3498db" name="CASH Payment" />
            <Line type="monotone" dataKey="totalCreditAmount" stroke="#e74c3c" name="CREDIT Payment" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <Table striped bordered hover className="table">
        <thead>
          <tr>
            <th>Month/Year</th>
            <th>Service Count</th>
            <th>Total Spare Parts Amount</th>
            <th>Total Labor Amount</th>
            <th>Total CASH Amount</th>
            <th>Total CREDIT Amount</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr key={index}>
              <td>{data.monthYear}</td>
              <td>{data.serviceCount}</td>
              <td>Rs.{data.totalSparePartsAmount.toFixed(2)}</td>
              <td>Rs.{data.totalLaborAmount.toFixed(2)}</td>
              <td>Rs.{data.totalCashAmount.toFixed(2)}</td>
              <td>Rs.{data.totalCreditAmount.toFixed(2)}</td>
              <td>Rs.{(data.totalExtraAmount).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ViewAllServicesDetailsEachMonth;