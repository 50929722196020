/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Tabs, Tab, Modal, Row, Col, Dropdown } from 'react-bootstrap';
import './ViewAllServices.css';
import SearchServices from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPrint, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import Invoice from './Invoice';
import ExtraInvoice from './ExtraInvoice';
import ColoredInvoice from './ColoredInvoice';
import ColoredExtraInvoice from './ColoredExtraInvoice';

const ViewAllServices = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(5); // Number of customers per page
  const [servicesPerPage] = useState(5);
  const [selectedService, setSelectedService] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [key, setKey] = useState('CASH');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    fetchServices();
  }, []);

//   useEffect(() => {
//     const storedUserData = localStorage.getItem('userData');
//     if (storedUserData) {
//       const parsedUserData = JSON.parse(storedUserData); // Parse the JSON string
//       setUserData(parsedUserData); // Update the state
//       console.log("Staff ID: " + parsedUserData.staff_id); // Access the staff_id from the parsed object
//     }
// }, []);

// useEffect(() => {
//   localStorage.setItem('userData', JSON.stringify(userData));
// }, [userData]);

const fetchServices = async () => {
  try {
    // Retrieve parsedUserData from localStorage
    const storedUserData = localStorage.getItem('userData');
    if (!storedUserData) {
      throw new Error('User data not found in localStorage');
    }

    const parsedUserData = JSON.parse(storedUserData);

    // Ensure staff_id is available
    if (!parsedUserData.staff_id) {
      throw new Error('Staff ID not found in user data');
    }

    // Fetch services using staff_id
    const response = await fetch(`${BASE_URL}/service/staff/${parsedUserData.staff_id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch services');
    }

    const servicesData = await response.json();

    // Fetch additional customer and invoice details for each service
    const servicesWithCustomers = await Promise.all(
      servicesData.map(async (service) => {
        try {
          if (service.customer_id) {
            // Fetch customer details
            const customerResponse = await fetch(`${BASE_URL}/customers/${service.customer_id}`);
            if (!customerResponse.ok) {
              throw new Error(`Failed to fetch customer for customer ID ${service.customer_id}`);
            }
            const customerData = await customerResponse.json();

            if (customerData.data && customerData.data.firstName && customerData.data.lastName) {
              // Fetch invoice details
              const invoiceResponse = await fetch(`${BASE_URL}/service/${service.service_id}`);
              const invoiceData = await invoiceResponse.json();

              // Fetch extra invoice details
              const extraInvoiceResponse = await fetch(`${BASE_URL}/service/updatesave/${service.service_id}`);
              const extraInvoiceData = await extraInvoiceResponse.json();

              return {
                ...service,
                customerName: `${customerData.data.title || ''} ${customerData.data.firstName || ''} ${customerData.data.lastName || ''}`.trim(),
                customerEmail: customerData.data.email || '',
                customerContactNumber: customerData.data.contactNo || '',
                invoice: invoiceData || {},
                extraInvoice: extraInvoiceData || {},
                coloredInvoice: invoiceData || {},
                coloredExtraInvoice: extraInvoiceData || {},
              };
            } else {
              throw new Error(`Invalid customer data for customer ID ${service.customer_id}`);
            }
          } else {
            throw new Error(`Customer ID not found for service ID ${service._id}`);
          }
        } catch (error) {
          console.error('Error fetching customer data:', error);
          return {
            ...service,
            customerName: 'Unknown',
            customerEmail: 'Unknown',
            customerContactNumber: 'Unknown',
            invoice: {},
            extraInvoice: {},
            coloredInvoice: {},
            coloredExtraInvoice: {},
          };
        }
      })
    );

    // Sort services by creation date (descending)
    servicesWithCustomers.sort((a, b) => new Date(b.createdAtService) - new Date(a.createdAtService));

    setServices(servicesWithCustomers);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  const filteredServices = services.filter(service => {
    const invoiceId = service.service_id || '';
    const customerName = service.customerName || '';
    const vehicleNumber = service.vehicle_no || '';
    
    const searchQuery = searchTerm.toLowerCase();
  
    return (
      invoiceId.toLowerCase().includes(searchQuery) ||
      customerName.toLowerCase().includes(searchQuery) ||
      vehicleNumber.toLowerCase().includes(searchQuery)
    );
  });

  const servicesByPaymentMethod = filteredServices.filter(service => {
    if (key === 'CASH') {
      return service.paymentType === 'CASH';
    } else if (key === 'CREDIT') {
      return service.paymentType === 'CREDIT';
    }
    return true;
  });

  // Logic to calculate current customers on current page
  const indexOfLastService = currentPage * customersPerPage;
  const indexOfFirstService = indexOfLastService - customersPerPage;
  const currentCustomers = filteredServices && filteredServices.slice(indexOfFirstService, indexOfLastService);

  // Ensure filteredCustomers is defined before calculating totalPages
  const totalPages = filteredServices ? Math.ceil(filteredServices.length / customersPerPage) : 0;

  const generateInvoice = (id, type) => {
    const service = services.find(service => service.service_id === id);
    if (service) {
      setSelectedService(service);
      setInvoiceType(type);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedService(null);
    setShowInvoiceModal(false);
    setInvoiceType('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowDeleteModal = (id) => {
    setServiceToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setServiceToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    if (!serviceToDelete) return;
  
    try {
      const response = await fetch(`${BASE_URL}/service/delete/${serviceToDelete}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        // Remove the deleted service from the list and re-calculate the total pages
        const updatedServices = services.filter(service => service.service_id !== serviceToDelete);
        setServices(updatedServices);
  
        // Adjust current page if needed after deletion
        const updatedTotalPages = Math.ceil(updatedServices.length / servicesPerPage);
        if (currentPage > updatedTotalPages) {
          setCurrentPage(updatedTotalPages); // Go to last available page if current one is now empty
        }
  
        toast.success('Service deleted successfully');
      } else {
        throw new Error('Failed to delete service');
      }
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service. Please try again.');
    } finally {
      handleCloseDeleteModal();
    }
  };  

  return (
    <div className="view-all-services">
      <h2 className="text-center">All Services</h2>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="CASH" title="CASH">
          <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Service ID</th>
                <th>Vehicle Number</th>
                <th>Created Date</th>
                <th>Total Amount</th>
                <th>Customer Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
      {servicesByPaymentMethod
        .filter(service => service.paymentType === 'CASH')
        .slice(indexOfFirstService, indexOfLastService)
        .map(service => (
          <tr key={service.service_id}>
  <td>{service.service_id}</td>
  <td>{service.vehicle_no}</td>
  <td>{new Date(service.createdAtService).toLocaleString()}</td>
  <td>Rs. {service.totalExtraAmount}.00</td>
  <td>{service.customerName}</td>
  <td className="d-flex align-items-center gap-2">
    <Link to={`/update/${service.service_id}`} className="action-button">
      <Button variant="warning"><FaEdit /></Button>
    </Link>
    <Link to={`/view/${service.service_id}`} className="action-button">
      <Button variant="primary"><FaEye /></Button>
    </Link>
    {service.service_status === 'Completed' && (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          <FaPrint />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'invoice')}>
            Standard Invoice
          </Dropdown.Item>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'extraInvoice')}>
            Spare Parts Invoice
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'coloredInvoice')}>
            Colored Standard Invoice
          </Dropdown.Item>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'coloredExtraInvoice')}>
            Colored Spare Parts Invoice
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )}
    <Button
      variant="danger"
      onClick={() => handleShowDeleteModal(service.service_id)}
      className="action-button"
    >
      <FaTrashAlt />
    </Button>
  </td>
</tr>
        ))}
    </tbody>
          </Table>
        </Tab>
        <Tab eventKey="CREDIT" title="CREDIT">
          <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Service ID</th>
                <th>Vehicle Number</th>
                <th>Created Date</th>
                <th>Total Amount</th>
                <th>Customer Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
      {servicesByPaymentMethod
        .filter(service => service.paymentType === 'CREDIT')
        .slice(indexOfFirstService, indexOfLastService)
        .map(service => (
          <tr key={service.service_id}>
  <td>{service.service_id}</td>
  <td>{service.vehicle_no}</td>
  <td>{new Date(service.createdAtService).toLocaleString()}</td>
  <td>Rs. {service.totalExtraAmount}.00</td>
  <td>{service.customerName}</td>
  <td className="d-flex align-items-center gap-2">
    <Link to={`/update/${service.service_id}`} className="action-button">
      <Button variant="warning"><FaEdit /></Button>
    </Link>
    <Link to={`/view/${service.service_id}`} className="action-button">
      <Button variant="primary"><FaEye /></Button>
    </Link>
    {service.service_status === 'Completed' && (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          <FaPrint />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'invoice')}>
            Standard Invoice
          </Dropdown.Item>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'extraInvoice')}>
            Spare Parts Invoice
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'coloredInvoice')}>
            Colored Standard Invoice
          </Dropdown.Item>
          <Dropdown.Item onClick={() => generateInvoice(service.service_id, 'coloredExtraInvoice')}>
            Colored Spare Parts Invoice
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )}
    <Button
      variant="danger"
      onClick={() => handleShowDeleteModal(service.service_id)}
      className="action-button"
    >
      <FaTrashAlt />
    </Button>
  </td>
</tr>
        ))}
    </tbody>
          </Table>
        </Tab>
      </Tabs>

      <Row className="justify-content-center">
        <Col xs="auto">
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
        </Col>
      </Row>

      {selectedService && (
        <Modal show={showInvoiceModal} onHide={handleCloseInvoice} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {invoiceType === 'invoice' ? (
  <Invoice service={selectedService} />
) : invoiceType === 'extraInvoice' ? (
  <ExtraInvoice service={selectedService} />
) : invoiceType === 'coloredInvoice' ? (
  <ColoredInvoice service={selectedService} />
) : invoiceType === 'coloredExtraInvoice' ? (
  <ColoredExtraInvoice service={selectedService} />
) : invoiceType === 'summary' ? (
  <Invoice service={selectedService} />
) : (
  <div>No valid invoice type selected</div>
)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseInvoice}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this service?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Invoice
        show={showInvoiceModal && invoiceType === 'invoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
      <ExtraInvoice
        show={showInvoiceModal && invoiceType === 'extraInvoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
      <ColoredInvoice
        show={showInvoiceModal && invoiceType === 'coloredInvoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
      <ColoredExtraInvoice
        show={showInvoiceModal && invoiceType === 'coloredExtraInvoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
    </div>
  );
};

export default ViewAllServices;