/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { VictoryChart, VictoryBar, VictoryPie } from 'victory';
import axios from 'axios';
import './Dashboard.css';
import BASE_URL from '../components/config';
import { Link } from 'react-router-dom';
import { FaWrench, FaUsers, FaUser, FaFileAlt } from 'react-icons/fa';
import LoadingScreen from './features/LoadingScreen';
import { BsWalletFill } from "react-icons/bs";

const Dashboard = ({ userData }) => {
  const [serviceCount, setServiceCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [quotationCount, setQuotationCount] = useState(0);
  const [monthlyServiceData, setMonthlyServiceData] = useState([]);
  const [latestServiceData, setLatestServiceData] = useState([]);
  const [latestQuotationData, setLatestQuotationData] = useState([]);
  const [pendingServiceCount, setPendingServiceCount] = useState(0);
  const [completedServiceCount, setCompletedServiceCount] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const [monthlyIncomeData, setMonthlyIncomeData] = useState([]);
  const [cashCount, setCashCount] = useState(0);
  const [creditCount, setCreditCount] = useState(0);

  // Define monthNames array within the component
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  useEffect(() => {
    fetchServiceCount();
    fetchStaffCount();
    fetchCustomerCount();
    fetchQuotationCount();
    fetchMonthlyServiceData();
    fetchLatestServiceData();
    fetchLatestQuotationData();
    fetchMonthlyIncomeData();
  }, []);

  useEffect(() => {
    if (latestServiceData.length > 0) {

      const vehiclePartCount = latestServiceData.reduce((count, service) => 
        count + (service.tasks ? service.tasks.filter(task => task.taskType === 'VehiclePart').length : 0), 0);
      const laborCount = latestServiceData.reduce((count, service) => 
        count + (service.extraTasks ? service.extraTasks.filter(extra => extra.extraTaskType === 'Labor').length : 0), 0);

      setPendingServiceCount(vehiclePartCount);
      setCompletedServiceCount(laborCount);
    }
  }, [latestServiceData]);

  const fetchServiceCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/service`);
      const services = response.data;
  
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
  
      const currentMonthCount = services.filter(service => {
        const serviceDate = new Date(service.createdAtService);
        return serviceDate.getMonth() === currentMonth && serviceDate.getFullYear() === currentYear;
      }).length;
  
      setServiceCount(currentMonthCount);
    } catch (error) {
      console.error('Error fetching service count:', error);
    }
  };

  const countPaymentTypes = (data) => {
    return data.reduce((acc, record) => {
      acc[record.paymentType] = (acc[record.paymentType] || 0) + 1;
      return acc;
    }, {});
  };
  
  const fetchPaymentTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/service`);
      console.log("API Response:", response.data); // Log the full response data
  
      const servicesData = response.data;
  
      // Check if servicesData is an array before counting
      if (!Array.isArray(servicesData)) {
        console.error('Expected servicesData to be an array but got:', servicesData);
        return; // Exit if not an array
      }
  
      // Count payment types
      const paymentCounts = countPaymentTypes(servicesData);
  
      // Update the state with the counts
      setCashCount(paymentCounts["CASH"] || 0);
      setCreditCount(paymentCounts["CREDIT"] || 0);
    } catch (error) {
      console.error('Error fetching payment types:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };  
  
  // Call the function to get payment counts
  fetchPaymentTypes();
  
  const fetchStaffCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/staff`);
      const staff = response.data;
  
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
  
      const currentMonthCount = staff.filter(member => {
        const hireDate = new Date(member.hireDate); // Assuming there's a hireDate field
        return hireDate.getMonth() === currentMonth && hireDate.getFullYear() === currentYear;
      }).length;
  
      setStaffCount(currentMonthCount);
    } catch (error) {
      console.error('Error fetching staff count:', error);
    }
  };
  
  const fetchCustomerCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/customers`);
      const customers = response.data.data;
  
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
  
      const currentMonthCount = customers.filter(customer => {
        const registrationDate = new Date(customer.createdAtCustomer); // Assuming there's a createdAtCustomer field
        return registrationDate.getMonth() === currentMonth && registrationDate.getFullYear() === currentYear;
      }).length;
  
      setCustomerCount(currentMonthCount);
    } catch (error) {
      console.error('Error fetching customer count:', error);
    }
  };
  
  const fetchQuotationCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/quotation`);
      const quotations = response.data;
  
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
  
      const currentMonthCount = quotations.filter(quotation => {
        const creationDate = new Date(quotation.createdAtQuotation); // Assuming there's a createdAtQuotation field
        return creationDate.getMonth() === currentMonth && creationDate.getFullYear() === currentYear;
      }).length;
  
      setQuotationCount(currentMonthCount);
    } catch (error) {
      console.error('Error fetching quotation count:', error);
    }
  };  

  const fetchMonthlyIncomeData = async () => {
    // try {
    //   const response = await axios.get(`${BASE_URL}/service/income_by_month`);
    //   const incomeData = response.data;
    //   const monthlyData = monthNames.map((month, index) => ({
    //     month,
    //     totalIncome: incomeData[index + 1] || 0
    //   }));
    //   setMonthlyIncomeData(monthlyData);
    // } catch (error) {
    //   console.error('Error fetching monthly income data:', error);
    // }
  };

  const fetchMonthlyServiceData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/service`);
      const services = response.data;
      const monthlyData = new Array(12).fill(0);
      services.forEach(service => {
        const month = new Date(service.createdAtService).getMonth();
        monthlyData[month]++;
      });
      setMonthlyServiceData(monthlyData);
    } catch (error) {
      console.error('Error fetching monthly service data:', error);
    }
  };

  const fetchLatestServiceData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/service?limit=5`);
      const services = response.data.sort((a, b) => new Date(b.createdAtService) - new Date(a.createdAtService));

      // Fetch customer names for each service
      const servicesWithCustomers = await Promise.all(
        services.map(async (service) => {
          if (service.customer_id) {
            try {
              const customerResponse = await axios.get(`${BASE_URL}/customers/${service.customer_id}`);
              const customerData = customerResponse.data;
              return {
                ...service,
                customer_name: `${customerData.data.firstName} ${customerData.data.lastName}`
              };
            } catch (error) {
              console.error(`Error fetching customer data for service ID ${service.service_id}:`, error);
              return { ...service, customer_name: 'Unknown' };
            }
          } else {
            return { ...service, customer_name: 'Unknown' };
          }
        })
      );

      setLatestServiceData(servicesWithCustomers);
      setLoading(false); // Turn off loading spinner once data is fetched
    } catch (error) {
      console.error('Error fetching latest service data:', error);
      setLoading(false); // Ensure loading state is turned off in case of error
    }
  };

  const fetchLatestQuotationData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/quotation?limit=5`);
      const quotations = response.data.sort((a, b) => new Date(b.createdAtQuotation) - new Date(a.createdAtQuotation));

      // Fetch customer names for each service
      const quotationsWithCustomers = await Promise.all(
        quotations.map(async (quotation) => {
          if (quotation.customer_id) {
            try {
              const customerResponse = await axios.get(`${BASE_URL}/customers/${quotation.customer_id}`);
              const customerData = customerResponse.data;
              return {
                ...quotation,
                customer_name: `${customerData.data.firstName} ${customerData.data.lastName}`
              };
            } catch (error) {
              console.error(`Error fetching customer data for service ID ${quotation.service_id}:`, error);
              return { ...quotation, customer_name: 'Unknown' };
            }
          } else {
            return { ...quotation, customer_name: 'Unknown' };
          }
        })
      );

      setLatestQuotationData(quotationsWithCustomers);
      setLoading(false); // Turn off loading spinner once data is fetched
    } catch (error) {
      console.error('Error fetching latest quotation data:', error);
      setLoading(false); // Ensure loading state is turned off in case of error
    }
  };

  const calculateTotalIncome = () => {
    return monthlyIncomeData.reduce((total, data) => total + data.totalIncome, 0);
  };

  const renderLatestServices = () => {
    if (latestServiceData.length === 0) {
      return <tr><td colSpan="6">No service records found.</td></tr>;
    }

    // Display only the first 5 records
    const limitedData = latestServiceData.slice(0, 5);

    return limitedData.map(service => (
      <tr key={service.service_id}>
        <td>{service.service_id}</td>
        <td>{service.vehicle_no}</td>
        <td>{service.customer_name}</td>
        <td>{service.vehicle_no}</td>
        {/* <td>{service.service_status}</td> */}
        <td>{new Date(service.createdAtService).toLocaleString()}</td>
        <td>
          <Link to={`/view/${service.service_id}`} className="action-button">
            <Button variant="primary" className="mr-2">View</Button>
          </Link>
        </td>
      </tr>
    ));
  };

  const renderLatestQuotations = () => {
    if (latestServiceData.length === 0) {
      return <tr><td colSpan="6">No quotations records found.</td></tr>;
    }

    // Display only the first 5 records
    const limitedData = latestQuotationData.slice(0, 5);

    return limitedData.map(quotation => (
      <tr key={quotation.service_id}>
        <td>{quotation.quotation_id}</td>
        <td>{quotation.vehicle_no}</td>
        <td>{quotation.customer_name}</td>
        <td>{quotation.vehicle_no}</td>
        <td>{new Date(quotation.createdAtQuotation).toLocaleString()}</td>
        <td>
          <Link to={`/view-quotation/${quotation.quotation_id}`} className="action-button">
            <Button variant="primary" className="mr-2">View</Button>
          </Link>
        </td>
      </tr>
    ));
  };

  if (loading) {
    return (
      <Container fluid className="dashboard">
        <Row>
          <LoadingScreen/>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="dashboard">
      <Row>
      <Col md={4}>
      <Link to='/view-all-staff-lm' style={{textDecoration: "none", color: "white"}}>
        <Card className="info-card2">
          <Card.Body>
            <Row>
              <Col>
            <FaUsers size={50} />
            </Col>
            <Col>
            <Card.Title>{staffCount}</Card.Title>
            <Card.Text>Staff</Card.Text>
            </Col>
            </Row>
          </Card.Body>
        </Card>
        </Link>
      </Col>
      <Col md={4}>
      <Link to='/view-all-customers-lm' style={{textDecoration: "none", color: "white"}}>
        <Card className="info-card3">
          <Card.Body>
            <Row>
              <Col>
            <FaUser size={50} />
            </Col>
            <Col>
            <Card.Title>{customerCount}</Card.Title>
            <Card.Text>Customers</Card.Text>
            </Col>
            </Row>
          </Card.Body>
        </Card>
        </Link>
      </Col>
      <Col md={4}>
      <Link to='/view-all-quotations-lm' style={{textDecoration: "none", color: "white"}}>
        <Card className="info-card1">
          <Card.Body>
            <Row>
              <Col>
            <FaWrench size={50} />
            </Col>
            <Col>
            <Card.Title>{quotationCount}</Card.Title>
            <Card.Text>Quotations</Card.Text>
            </Col>
            </Row>
          </Card.Body>
        </Card>
        </Link>
      </Col>
      </Row>
      <Row>
      <Col md={6}>
      <Link to='/view-all-services-lm' style={{textDecoration: "none", color: "white"}}>
        <Card className="info-card4">
          <Card.Body>
            <Row>
              <Col>
            <FaFileAlt size={50} />
            </Col>
            <Col>
            <Card.Title>{serviceCount}</Card.Title>
            <Card.Text>Services</Card.Text>
            </Col>
      </Row>
          </Card.Body>
        </Card>
        </Link>
      </Col>
      <Col md={6}>
    <Link to='/view-all-service-details' style={{ textDecoration: "none", color: "white" }}>
      <Card className="info-card5">
        <Card.Body>
          <Row>
            <Col>
              <BsWalletFill size={50} />
            </Col>
            <Col>
              <Card.Title>CASH: {cashCount} | CREDIT: {creditCount}</Card.Title>
              <Card.Text>Income</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  </Col>
    </Row>
      <Row className="charts-section">
        <Col md={7}>
          <Card>
            <Card.Body>
              <Card.Title className='chart-title'>Monthly Vehicle Repair Analysis</Card.Title>
              <div className="chart-container">
                <VictoryChart domainPadding={20}>
                  <VictoryBar
                    data={monthlyServiceData.map((count, index) => ({ month: monthNames[index], count }))}
                    x="month"
                    y="count"
                    style={{
                      data: { fill: 'red' },
                    }}
                    tickFormat={(value) => value} // Display month names on x-axis
                  />
                </VictoryChart>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
      <Card>
        <Card.Body>
          <Card.Title className="chart-title">Service Types</Card.Title>
          <div className="chart-container-pie">
            <VictoryPie
              data={[
                { x: 'VehiclePart', y: pendingServiceCount },
                { x: 'Labor', y: completedServiceCount }
              ]}
              colorScale={['orange', 'green']}
              labels={() => ''} // Remove labels from the pie chart
            />
          </div>
          <div className="chart-legend">
            <div className="legend-item">
              <span className="legend-color" style={{ backgroundColor: 'orange' }}></span>
              <span>VehiclePart {pendingServiceCount}</span>
            </div>
            <div className="legend-item">
              <span className="legend-color" style={{ backgroundColor: 'green' }}></span>
              <span>Labor {completedServiceCount}</span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Latest 5 Service Records</Card.Title>
              <table className="table">
                <thead>
                  <tr>
                    <th>Service ID</th>
                    <th>Vehicle No</th>
                    <th>Customer Name</th>
                    <th>Vehicle No</th>
                    {/* <th>Service Status</th> */}
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renderLatestServices()}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
<br/>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Latest 5 Quotation Records</Card.Title>
              <table className="table">
                <thead>
                  <tr>
                    <th>Quotation ID</th>
                    <th>Vehicle No</th>
                    <th>Customer Name</th>
                    <th>Vehicle No</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renderLatestQuotations()}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;