/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { Button, Modal, Row, Col, Table } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './ColoredInvoice.css';
import logo from '../assets/logo5121.jpg';

const StyledInvoice = ({ show, handleClose, service }) => {
    const printRef = useRef();

    // const addSignatureSection = (pdf, x, y) => {
    //     pdf.setFontSize(10);
    //     pdf.text('AUTHORIZED SIGNATURE: ________________________', y, x);
    //     pdf.text('CUSTOMER SIGNATURE: ________________________', y, x + 100);
    // };

    const handlePrint = () => {
        if (!printRef.current) return;

        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        const afterPrint = () => {
            document.body.innerHTML = originalContents;
            window.location.reload();
        };

        window.addEventListener('afterprint', afterPrint);

        window.print();

        window.removeEventListener('afterprint', afterPrint);
    };

    const handleDownload = () => {
        const element = printRef.current;

        html2canvas(element, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;

            let position = 0;
            const totalPages = Math.ceil(imgHeight / pdfHeight);

            for (let i = 0; i < totalPages; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                pdf.addImage(imgData, 'PNG', 0, -position, imgWidth, imgHeight);
                // addSignatureSection(pdf, 10, pdfHeight - 20);
                position += pdfHeight;
            }

            pdf.save(`Invoice_${service.service_id}_${service.vehicle_no}.pdf`);
        });
    };

    if (!service) return null;

    const partsTotal = service.tasks?.reduce((acc, task) => (task.amount ? acc + task.amount : acc), 0) || 0;
    const laborTotal = service.extraTasks?.reduce((acc, extraTask) => (extraTask.extraAmount ? acc + extraTask.extraAmount : acc), 0) || 0;
    const totalAmount = partsTotal + laborTotal;

    const hasTasks = service.tasks && service.tasks.some(task => task.task && task.unit && task.amount);
    const hasExtraTasks = service.extraTasks && service.extraTasks.some(extraTask => extraTask.extraTask && extraTask.extraAmount);

    return (
        <Modal show={show} onHide={handleClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>INVOICE DETAILS</Modal.Title>
            </Modal.Header>
            <Modal.Body className="invoice-body">
                <div ref={printRef} className="invoice-container">
                    <div className="invoice-header">
                        <Row className="align-items-center">
                        <Col>
                        <Row className="align-items-center">
    <Col xs="auto">
        <img 
            src={logo} 
            alt="Logo" 
            className="logo" 
            style={{ width: "140px", height: "70px", marginRight: "15px" }} // Slightly larger for visibility
        />
    </Col>
    <Col>
        <h1 className="company-name" style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#D10000" }}>
            NEXGEN AUTOCARE
        </h1>
    </Col>
</Row>
        </Col>
                        </Row>
                    </div>
                    <div>
                    {/* <h5 className="invoice-title">INVOICE</h5>
                    <hr /> */}
                    <Row>
    <Col className="customer-info">
        <div className="invoice-details-box">
            <h6 className="section-title">CUSTOMER DETAILS</h6>
            <p className="customer-data">{service.customerName}</p>
            <p className="customer-data">{service.customerContactNumber}</p>
            <p className="customer-data">{service.customerEmail}</p>
            <p className="customer-data">{new Date(service.createdAtService).toLocaleDateString('en-GB')}</p>
        </div>
    </Col>
    <Col className="company-info">
        <div>
            <h6 className="section-title">COMPANY DETAILS</h6>
            <p className="company-address">236/3, Pragathi Mawatha</p>
            <p className="company-address">Katuwana Road Homagama</p>
            <p className="company-email">nextgenautocarelk@gmail.com</p>
            <p className="company-contact">Contact: 0775411060</p>
        </div>
    </Col>
</Row>
</div>
                    <div>
    <Table style={{borderRadius: "14px"}}>
        <tbody  style={{borderRadius: "14px"}}>
            <tr className="invoice-details-box">
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>INVOICE NO</td>
                <td>{service.service_id}</td>
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>VEHICLE NO</td>
                <td>{service.vehicle_no}</td>
            </tr>
            <tr className="invoice-details-box">
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>DATE</td>
                <td>{new Date(service.createdAtService).toLocaleDateString('en-GB')}</td>
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>ODO METER</td>
                <td>{service.odoMeter}</td>
            </tr>
            <tr className="invoice-details-box">
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>PAYMENT TYPE</td>
                <td>{service.paymentType}</td>
                <td className="table-header" style={{backgroundColor: "#bebaba"}}>VEHICLE MODEL</td>
                <td>{service.vehicleModel}</td>
            </tr>
        </tbody>
    </Table>
</div>
                    {hasTasks && (
                        <Table className="task-table">
                            <thead>
                                <tr>
                                    <th  style={{backgroundColor: "black", color: "white"}}>SPARE PART</th>
                                    <th  style={{backgroundColor: "black", color: "white"}}>QUANTITY</th>
                                    <th  style={{backgroundColor: "black", color: "white"}}>RATE (RS.)</th>
                                    <th  style={{backgroundColor: "black", color: "white"}}>AMOUNT (RS.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {service.tasks.map((task, index) => (
                                    <tr key={index} style={{bor: "none"}}>
                                        <td>{task.task}</td>
                                        <td>{task.unit}</td>
                                        <td>{task.amount / task.unit}.00</td>
                                        <td>{task.amount}.00</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td className="table-total-label">PARTS TOTAL</td>
                                    <td>{partsTotal}.00</td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                    {hasExtraTasks && (
                        <Table className="task-table">
                            <thead  style={{backgroundColor: "black"}}>
                                <tr>
                                    <th  style={{backgroundColor: "black", color: "white"}}>LABOUR DESCRIPTION</th>
                                    <th  style={{backgroundColor: "black", color: "white"}}>CHARGE (RS.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {service.extraTasks.map((extraTask, index) => (
                                    <tr key={index}>
                                        <td>{extraTask.extraTask}</td>
                                        <td>{extraTask.extraAmount}.00</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="table-total-label">LABOUR TOTAL</td>
                                    <td>{laborTotal}.00</td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                    <div className="invoice-footer">
                        <h5 className="total-amount">TOTAL: {totalAmount}.00</h5>
                    </div>
                    <br/> 
                    <div class="signature-section">
    <p>
    <span>................................</span>
    <br/>
      <span style={{fontSize: "13px"}}>AUTHORIZED SIGNATURE</span>
    </p>
    <p class="center-text"  style={{fontSize: "8px"}}>
      Make all checks payable to <br/>
      Commercial Bank - Kollupitiya <br/>
      Branch Code - 011 <br/>
      Account Holder - A.I Weliwatta <br/>
      Acc No - 1000644225
    </p>
    <p>
    <span>................................</span>
    <br/>
      <span  style={{fontSize: "13px"}}>CUSTOMER SIGNATURE</span>
    </p>
  </div>
                </div>
                

               
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handlePrint}>
                    <FaPrint /> Print
                </Button>
                <Button variant="success" onClick={handleDownload}>Download</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StyledInvoice;