/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Form, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UpdateService.css';

const UpdateService = ({ userData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);

  const initialFormData = {
    vehicle_no: '',
    desc: '',
    odoMeter: '',
    vehicleModel: '',
    paymentType: '',
    createdAtService: '',
    tasks: [],
    extraTasks: [],
    totalExtraAmount: 0,
    staffId: userData.staff_id || ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const taskRefs = useRef([]);
  const extraTaskRefs = useRef([]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/service/${id}`);
        const data = response.data;
        setService(data);
        setFormData({
          vehicle_no: data.vehicle_no || '',
          desc: data.desc || '',
          createdAtService: data.createdAtService || '',
          odoMeter: data.odoMeter || '',
          vehicleModel: data.vehicleModel || '',
        paymentType: data.paymentType || '',
          tasks: data.tasks || [],
          extraTasks: data.extraTasks || [],
          totalExtraAmount: data.totalExtraAmount || 0,
          staffId: data.staffId || ''
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching service:', error);
        setLoading(false);
        toast.error('Failed to fetch service details. Please try again.');
      }
    };

    fetchService();
  }, [id, userData.staff_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTasks = formData.tasks.map((task, i) =>
      i === index ? { ...task, [name]: value } : task
    );
    setFormData({ ...formData, tasks: updatedTasks });
    calculateTotalAmount(updatedTasks, formData.extraTasks);
  };

  const handleExtraTaskChange = (index, e) => {
    const { name, value } = e.target;
    const updatedExtraTasks = formData.extraTasks.map((task, i) =>
      i === index ? { ...task, [name]: value } : task
    );
    setFormData({ ...formData, extraTasks: updatedExtraTasks });
    calculateTotalAmount(formData.tasks, updatedExtraTasks);
  };

  const calculateTotalAmount = (tasks, extraTasks) => {
    let totalAmount = 0;

    tasks.forEach(task => {
      if (task.amount) {
        totalAmount += parseInt(task.amount, 10);
      }
    });

    extraTasks.forEach(extraTask => {
      if (extraTask.extraAmount) {
        totalAmount += parseInt(extraTask.extraAmount, 10);
      }
    });

    setFormData(prevState => ({
      ...prevState,
      totalAmount
    }));
  };

  const handleAddTask = () => {
    setFormData(prevState => ({
      ...prevState,
      tasks: [...prevState.tasks, { task: '', unit: '', amount: '' }]
    }));
  };

  const handleAddExtraTask = () => {
    setFormData(prevState => ({
      ...prevState,
      extraTasks: [...prevState.extraTasks, { extraTask: '', extraAmount: '' }]
    }));
  };

  const handleUpdateService = async () => {
    const { vehicle_no, desc, createdAtService, odoMeter, vehicleModel, paymentType, tasks, extraTasks, totalExtraAmount, staffId } = formData;
    // if (!vehicle_no || !desc || !staffId) {
    //   toast.error('Please fill in all fields.');
    //   return;
    // }

    try {
      const response = await axios.put(`${BASE_URL}/service/update/${id}`, {
        vehicle_no,
        desc,
        createdAtService,
        odoMeter,
        vehicleModel,
        paymentType,
        tasks,
        extraTasks,
        totalExtraAmount,
        staffId
      });
      console.log('Service updated:', response.data);
      toast.success('Service updated successfully.');
      navigate('/view-all-services');
    } catch (error) {
      console.error('Error updating service:', error);
      toast.error('Failed to update service. Please try again.');
    }
  };

  const handleSaveUpdatedService = async () => {
    const { vehicle_no, desc, createdAtService, odoMeter, vehicleModel, paymentType, tasks, extraTasks, totalExtraAmount, staffId } = formData;
    if (!vehicle_no || !staffId) {
      toast.error('Please fill in all fields.');
      return;
    }
  
    console.log('Saving updated service with data:', {
      vehicle_no,
      desc,
      createdAtService,
      odoMeter,
      vehicleModel,
      paymentType,
      tasks,
      extraTasks,
      totalExtraAmount,
      staffId
    });
  
    try {
      const response = await axios.put(`${BASE_URL}/service/update_and_save/${id}`, {
        vehicle_no,
        desc,
        odoMeter,
        vehicleModel,
        createdAtService,
        paymentType,
        tasks,
        extraTasks,
        totalExtraAmount,
        staffId
      });
      console.log('Updated service saved:', response.data);
      toast.success('Updated service saved successfully.');
      navigate('/view-all-services');
    } catch (error) {
      console.error('Error saving updated service:', error);
      toast.error('Failed to save updated service. Please try again.');
    }
  };  

  const handleKeyDown = (e, index, listType) => {
    const refs = listType === 'task' ? taskRefs.current : extraTaskRefs.current;
    const maxIndex = refs.length - 1;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (index < maxIndex) refs[index + 1].focus();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (index > 0) refs[index - 1].focus();
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      const row = Math.floor(index / 3);
      const col = index % 3;
      const nextIndex = row * 3 + (col + 1);
      if (col < 2 && nextIndex <= maxIndex) refs[nextIndex].focus();
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      const row = Math.floor(index / 3);
      const col = index % 3;
      const prevIndex = row * 3 + (col - 1);
      if (col > 0 && prevIndex >= 0) refs[prevIndex].focus();
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="update-service">
      <h2>Update Service</h2>
      <Form>
        <Row>
          <Col>
            <Form.Group controlId="vehicle_no">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control
                type="text"
                name="vehicle_no"
                value={formData.vehicle_no}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="vehicleModel">
              <Form.Label>Vehicle Model</Form.Label>
              <Form.Control
                type="text"
                name="vehicleModel"
                value={formData.vehicleModel}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="createdAtService">
              <Form.Label>created At Service</Form.Label>
              <Form.Control
                type="date"
                name="createdAtService"
                value={formData.createdAtService}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="odoMeter">
              <Form.Label>ODO Meter</Form.Label>
              <Form.Control
                type="text"
                name="odoMeter"
                value={formData.odoMeter}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Form.Group controlId="desc">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="desc"
            value={formData.desc}
            onChange={handleChange}
          />
        </Form.Group>
        <br />
        <Form.Group controlId="paymentType">
  <Form.Label>Payment Type</Form.Label>
  <Form.Control
    as="select"
    name="paymentType"
    value={formData.paymentType}
    onChange={handleChange}
  >
    <option value="">Select Payment Type</option>
    <option value="CASH">CASH</option>
    <option value="CREDIT">CREDIT</option>
  </Form.Control>
</Form.Group>
<br />
        <Form.Group>
          <Form.Label>Spare Parts</Form.Label>
          {/* <Button onClick={handleAddTask} className="mb-2">Add Spare Part</Button> */}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Part Name</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.tasks.map((task, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      name="task"
                      value={task.task}
                      onChange={(e) => handleTaskChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'task')}
                      ref={(el) => (taskRefs.current[index] = el)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name="unit"
                      value={task.unit}
                      onChange={(e) => handleTaskChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'task')}
                      ref={(el) => (taskRefs.current[index] = el)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="amount"
                      value={task.amount}
                      onChange={(e) => handleTaskChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'task')}
                      ref={(el) => (taskRefs.current[index] = el)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => setFormData(prevState => ({
                        ...prevState,
                        tasks: prevState.tasks.filter((_, i) => i !== index)
                      }))}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={handleAddTask} className="mb-2">Add Spare Part</Button>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label>Labors</Form.Label>
          {/* <Button onClick={handleAddExtraTask} className="mb-2">Add Labor</Button> */}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Labor</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.extraTasks.map((extraTask, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      name="extraTask"
                      value={extraTask.extraTask}
                      onChange={(e) => handleExtraTaskChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'extraTask')}
                      ref={(el) => (extraTaskRefs.current[index] = el)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="extraAmount"
                      value={extraTask.extraAmount}
                      onChange={(e) => handleExtraTaskChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, 'extraTask')}
                      ref={(el) => (extraTaskRefs.current[index] = el)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => setFormData(prevState => ({
                        ...prevState,
                        extraTasks: prevState.extraTasks.filter((_, i) => i !== index)
                      }))}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={handleAddExtraTask} className="mb-2">Add Labor</Button>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Label>Total Amount</Form.Label>
          <Form.Control
            type="number"
            value={formData.totalExtraAmount}
            readOnly
          />
        </Form.Group>
        <br />
        <Button variant="primary" onClick={handleUpdateService}>Update Service</Button>
        <Button variant="secondary" onClick={handleSaveUpdatedService} className="ms-2">Save Updated Service</Button>
      </Form>
    </div>
  );
};

export default UpdateService;